import { IControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import {
  ApiTypes,
  CommonTypes,
  GroupsRequestBuilder,
  GroupsRequestSort,
} from '@wix/social-groups-api';
import { BaseGroupsController } from '../../../common/controllers/groups-list/BaseGroupsController';
import { GroupsWidgetProps } from '../../../common/types/groups-list/types';
import { GroupsPageData } from '../../Groups/Widget/App/types';
import { widgetSettingsParams } from '../../../common/settings/groups-list/widgetSettings';
import { settingsParams } from '../../../common/settings/groups-list/settings';
import type { ExperimentsBag, ControllerParams } from '@wix/yoshi-flow-editor';
import { UpdateProgress } from '../../../common/ContentEditor/UpdateProgress';

class GroupsController extends BaseGroupsController<GroupsWidgetProps> {
  constructor(
    controllerContext: ControllerParams,
    experiments: ExperimentsBag = {},
  ) {
    super(controllerContext);
  }

  protected async getGroups(
    config: IControllerConfig,
  ): Promise<ApiTypes.v1.GroupResponse[]> {
    const req = this.getRequestFromConfig(config);
    return this.groupListModel.listGroups(req);
  }

  private getRequestFromConfig(config: IControllerConfig) {
    const reqBuilder = new GroupsRequestBuilder();
    const sortBy = this.getSortBy(config);
    const order =
      sortBy === GroupsRequestSort.NAME
        ? CommonTypes.SortOrder.ASC
        : CommonTypes.SortOrder.DESC;
    const req = reqBuilder
      .addPaging(this.getLimit(config))
      .addSort(sortBy, order)
      .addFilter({
        approval_status: {
          $ne: 'PENDING',
        },
      })
      .build();
    return req;
  }

  private getLimit(config: any) {
    const numberGroups = this.controllerContext.flowAPI.settings.get(
      widgetSettingsParams.numberGroups,
    );
    return numberGroups;
  }

  async getInitialProps(): Promise<Partial<GroupsPageData>> {
    const groups = await this.getGroups(this.config);
    const groupUrls = await this.getGroupUrls(groups);
    this.cacheGroups(groups);
    return { groups, groupUrls, updateProgress: UpdateProgress.STALE };
  }

  private getSortBy(config: IControllerConfig) {
    return this.controllerContext.flowAPI.settings.get(settingsParams.sortBy);
  }
}

export default GroupsController;
